import React from "react"
import {
    Card,
    CardBody,
    Col,
    CardFooter,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

const TableSkeleton = ({datacount,listcount}) => {
    return (
        <React.Fragment>
            <div className="skeleton-table table-responsive">
                <table>
                    <thead>
                        <tr>
                            {[...Array(datacount)].map((item, index) => {
                                return <th key={index}>
                                    <Skeleton height={"24px"} width={'100px'} />
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(listcount)].map((item, index) => {
                            return <tr key={index}>
                                {[...Array(datacount)].map((item, index) => {
                                    return <td key={index}>
                                        <Skeleton height={"24px"} width={'100px'} />
                                    </td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default TableSkeleton
